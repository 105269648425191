<template>
  <div>
    <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/常电小圆wifi@3x_01.jpg" width="100%" height="100%">

    <van-tabs v-model="active" sticky swipeable animated offset-top="46px">
      <van-tab title="产品介绍">
        <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/长电小圆wifi@3x_02.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/长电小圆wifi@3x_03.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/长电小圆wifi@3x_04.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/长电小圆wifi@3x_05.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/长电小圆wifi@3x_06.jpg" width="100%" height="100%">
      </van-tab>
      <van-tab title="参数规格">
        <img src="../../../public/images/h5_xqy(1)/长电小圆wifi/长电小圆wifi@3x_07.jpg" width="100%" height="100%">
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "长电小圆WiFi-VO15T",
  data() {
    return {
      active: 0,
    };
  },
}
</script>

<style scoped>

</style>